export const WORD_COUNT = 100;
export const WORD_SIZE = 5;
export const TRACKING_ID = "UA-186342924-1";

const strings = {
  starter: {
    start: "START",
  },
};

export { strings };
